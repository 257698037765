<template>
  <q-card>
    <div
        v-for="(sequence, i) in data"
        :key="sequence.id"
        :class="i < data.length - 1 ? 'border-bottom q-pa-sm' : 'q-pa-sm'"
        @click="handleClick(sequence)"
    >
      <div class="text-right">
        <q-badge
            :label="$t(sequence.state)"
            :color="sequenceStateColors[sequence.state]"
            class="q-py-xs text-capitalize"
        />
      </div>

      <div class="row">
        <div class="col">
          {{ $t('Tasks') }}
        </div>

        <div class="col row justify-center">
          <q-token
              v-for="(task, i) in sequence.taskStates"
              :key="i"
              :color="tasksStateColors[task.state]"
              class="q-mr-sm"
          >
            {{ `${task.count}${task.state[0].toUpperCase()}` }}
          </q-token>
        </div>

        <div class="col"/>
      </div>

      <div class="text-subtitle1 text-weight-bold text-center">
        {{ 'Sequence: ' + sequence.id }}

        <div v-if="sequence.stats && sequence.stats['orders-list'] && sequence.stats['orders-list'].length === 1">
          {{ 'Order: ' + sequence.stats['orders-list'][0] }}
        </div>
      </div>

      <div
          v-if="sequence.stats && sequence.stats['orders-list'] && sequence.stats['orders-list'].length === 1 && sequence?.tasks"
          class="text-subtitle1 text-weight-bold text-center">
        {{ sequence.tasks[0]?.shopName + ' (' + sequence.tasks[0]?.shop + ')' }}
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'DynamicSequenceCollection',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      sequenceStateColors: {
        new: 'positive',
        closed: 'negative',
        processing: 'info'
      },
      tasksStateColors: {
        new: 'teal',
        confirmed: 'warning',
        complete: 'positive',
        rejected: 'negative',
      },
    }
  },
  computed: {
    title () {
      if (this.rawData.options) {
        return this.rawData.options.title
      }

      return this.rawData.title || ''
    }
  },
  methods: {
    handleClick (data) {
      const event = {
        type: this.rawData.entityClass,
        event: this.rawData.event,
        data
      }

      this.$emit('click', event)
    }
  }
}
</script>
